.AuditingLogTrailItem {
    &_Container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 32px;
    }

    &_Icon {
        min-width: 45px;
        max-width: 45px;
        width: 45px;
        min-height: 45px;
        max-height: 45px;
        height: 45px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 2px solid #44546F26;
        border-radius: 100px;

        &.Start{
            border-color: var(--color-border-neutral);
            & .MuiSvgIcon-root {
                color: var(--color-icon-disabled);
            }
        }
        &.Save{
            border-color: var(--color-border-information);
            & .MuiSvgIcon-root {
                color: var(--color-icon-information);
            }
        }
        &.High{
            border-color: var(--color-border-danger);
            & .MuiSvgIcon-root {
                color: var(--color-icon-danger);
            }
        }
        &.Medium{
            border-color: var(--color-border-warning);
            & .MuiSvgIcon-root {
                color: var(--color-icon-warning);
            }
        }
        &.Low{
            border-color: var(--color-border-success);
            & .MuiSvgIcon-root {
                color: var(--color-icon-success);
            }
        }
        &.None{
            border-color: var(--color-border-brand);
            & .MuiSvgIcon-root {
                color: var(--color-icon-brand);
            }
        }
    }

    &_Card {
        width: 100%;
        padding: 10px 18px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        border: none;
        border-radius: 7px;

        &.Start {
            background-color: var(--color-surface-disabled-light);
        }
        &.Save {
            background-color: var(--color-surface-information-contrast-light);
        }
        &.High {
            background-color: var(--color-surface-danger-contrast-light);
        }
        &.Medium {
            background-color: var(--color-surface-warning-contrast-light);
        }
        &.Low {
            background-color: var(--color-surface-success-contrast-light);
        }
        &.None {
            background-color: var(--color-surface-brand-contrast-light);
        }
    }

    &_Title.MuiTypography-root {
        color: var(--global-text-primary-color);
        font-size: 17px;
        font-weight: 600;
        line-height: 23.22px;
    }

    &_Description.MuiTypography-root {
        color: var(--global-text-primary-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
        white-space: pre-line;
    }
}
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/button';

.AccountCreationWorkspace {
    &_Content {
        gap: spacing.$xl;
    }

    &_Icon.MuiSvgIcon-root {
        color: var(--color-icon-brand);
        width: spacing.$xl-300;
        height: spacing.$xl-300;
    }

    &_TitleContainer {
        max-width: 604px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: spacing.$l;
    }

    &_Title {
        @include font.typography-semibold(h6);
    }

    &_Text {
        @include font.typography-regular;
    }
    
    &_List {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: spacing.$m;
    }

    &_List {
        &Icon {
            color: var(--color-icon-brand);
        }

        &Item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: spacing.$s;
        }

        &Text {
            @include font.typography-regular;
        }
    }

    &_Actions {
        margin-top: spacing.$s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: spacing.$m;
    }

    &_Action {
        @include button.default;
    }
}
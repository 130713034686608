@use 'src/styles/info';
@use 'src/styles/button';

.signUpError {
  &Container {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  &Icon {
    margin-bottom: 16px;
  }
  &Title.MuiTypography-root {
    @include info.title;
    text-align: center;
  }
  &Subtitle.MuiTypography-root {
    @include info.subtitle;
    text-align: center;
  }
  &Button {
    @include button.default;
  }

}
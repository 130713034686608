@use 'src/styles/info';
@use 'src/styles/button';

.paymentSuccess {
    &Container {
        padding: 30px;
        max-width: 830px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    &Icon {
        margin-bottom: 16px;
    }
    &Title.MuiTypography-root {
        @include info.title;
        text-align: center;
    }
    &Subtitle.MuiTypography-root {
        @include info.subtitle;
        text-align: center;
    }
    &Actions {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    &Button {
        @include button.default;
    }

}
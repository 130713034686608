@use 'src/styles/spacing';
@use 'src/styles/font';
@use 'src/styles/dimension';
@use 'src/styles/button';

.BudgetForm {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;

  &_Subtitle {
    @include font.typography-semibold(base);
    padding-bottom: spacing.$m-100;
  }

  &_AmountLabel, &_DateLabel {
    @include font.medium(xsmall);
  }

  &_TextField.MuiFormControl-root {
    margin-bottom: spacing.$s;

    & > div > .MuiInputBase-input {
      padding: spacing.$s-100;
    }
  }

  &_InfoAlert.MuiPaper-root {
    margin-bottom: spacing.$xl-300;
    padding: 0 spacing.$s-100;
    align-items: center;

    .MuiTypography-root {
      @include font.typography-regular(xsmall);
    }
  }

  &_CycleContainer {
    padding-bottom: spacing.$xl-300;
  }

  &_Description {
    @include font.typography-regular(small);
    padding-bottom: spacing.$m-100;
  }

  &_RadioGroup {
    display: flex;
    flex-direction: row;
    gap: spacing.$s-200;
    padding-bottom: spacing.$m-100;
  }

  &_Radio {
    display: flex;
    flex-direction: row;
    padding: 0 spacing.$s;
    border-radius: dimension.$s;
    flex-grow: 1;

    .MuiTypography-root {
      @include font.typography-regular(base);
    }
  }

  &_TimeUnit, &_PeriodNumber, &_FormControl {
    flex-grow: 1;
  }

  &_DatesContainer {
    padding-bottom: spacing.$xxl-300;

    &.customPadding {
      padding-bottom: spacing.$xl-400;
    }
  }

  &_Chip.MuiChip-root {
    border-radius: dimension.$xs;
    background-color: var(--color-surface-accent-contrast-light);

    & > span {
      @include font.regular(base);
      color: var(--color-text-accent-contrast);
    }
  }

  &_DateRadioFields {
    display: flex;
    flex-direction: row;
    gap: spacing.$m;
  }

  &_DateFields, &_DateRadioGroup {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &_DateFields {
    flex-grow: 1.5;
  }

  &_Buttons {
    display: flex;
    flex-direction: row;
    gap: spacing.$m;
    justify-content: flex-end;

    .MuiButtonBase-root {
      @include button.default;
    }
  }

  &_ErrorHint {
    @include font.typography-regular(base);
    color: var(--color-red);
    margin-left: spacing.$m;
  }

  &_Creatable {
    & [class$="-control"] {
      box-shadow: none;
    }
    & [class$="-multiValue"] {
      min-width: auto;
      background-color: var(--color-surface-accent-contrast-light);
    }
    & [class$="-MultiValueRemove"] {
      &:hover {
        background-color: inherit;
        color: inherit;
      }
      svg {
        fill: var(--color-icon-accent);
        width: spacing.$m-200;
        height: spacing.$m-200;
      }
    }
    & [class$="-ValueContainer"] {
      padding: spacing.$xs;
    }

    &.hasError {
      border: 1px solid #D3302F;
      border-radius: 5px;
      & [class$="-placeholder"] {
        color: #D3302F;
        font-weight: 500;
      }
    }
  }
}

.radioButtonCheckedIcon {
  color: var(--global-purple-color);
}

.rs-picker-popup-date {
  z-index: 9999;
}
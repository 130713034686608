.signUpContainer {
  display: flex;
  height: 100vh;
  position: fixed;
  width: 100vw;

  .contentColumn {
    display: flex;
    flex-direction: column;
    background: var(--gradient-info);
    flex: 0 0 45%;

    .narusLogo {
      width: 154px;
      height: 41px;
    }

    .contentColumnWrapper {
      display: flex;
      flex-direction: column;
      width: 65%;
      min-width: 419px;
      max-width: 419px;
      margin: 12vh 0 0 6vw;
    }

    @media (min-width: 1800px) {
      .contentColumnWrapper {
        margin: 25vh 0 0 8vw;
      }
    }

    @media (min-width: 2560px) {
      .contentColumnWrapper {
        margin: 30vh 0 0 10vw;
      }
    }

    .benefitsContainer {
      display: flex;
      flex-direction: column;
      gap: 31px;
    }

    .title {
      margin-top: 80px;
      color: #09151D;
      font-family: var(--font-inter);
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }

    .explanation.subtitle {
      margin: 40px 0 40px 0;
    }

    .explanation, .subtitle {
      color: #09151D;
      font-family: var(--font-inter);
      font-size: 14px;
      font-weight: 400;
    }

    .benefit {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .signUp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;
    flex: 0 0 55%;

    .cl-cardBox {
      width: 471px;
      box-shadow: none;

      .cl-card {
        box-shadow: none;
        align-items: center;
        padding: 0;
        gap: 40px;
      }
    }

    .cl-header {
      & > div {
        gap: 8px;
      }

      .cl-headerTitle {
        color: #09151D;
        font-family: var(--font-inter);
        font-size: 20px;
        font-weight: 600;
      }

      .cl-headerSubtitle {
        color: #010B11;
        font-family: var(--font-inter);
        font-size: 14px;
      }
    }

    .cl-main {
      gap: 16px;
    }

    .cl-socialButtons {
      border-radius: 4px;
      border: 1px solid #A375FC;
      background: #FFF;
      box-shadow: 0px 2px 9px -5px rgba(25, 28, 33, 0.20);

      .cl-socialButtonsBlockButton {
        color: var(--global-purple-color);
        font-family: var(--font-inter);
        font-size: 14px;
        font-weight: 400;

        & > span {
          gap: 5px;
        }
      }
    }

    .cl-socialButtonsBlockButton__google > span > span:nth-child(1) {
      background-color: var(--global-purple-color);
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-image: url('https://img.clerk.com/static/google.svg');
      -webkit-mask-size: cover;

      & > img {
        opacity: 0;
      }
    }

    .cl-dividerText {
      font-family: var(--font-inter);
      font-size: 14px;
      font-weight: 400;
    }

    .cl-form div:first-of-type {
      gap: 0;

      .cl-formFieldRow:first-of-type {
        margin-bottom: 8px;
      }
    }

    .cl-formFieldLabelRow {
      margin-bottom: 3px;
    }

    .cl-formFieldLabel {
      color: #172B4D;
      font-family: var(--font-inter);
      font-size: 12px;
      font-weight: 400;
    }

    .cl-formFieldInput, .cl-formButtonPrimary, .cl-socialButtons {
      width: 361px;
      height: 34px;
      border-radius: 4px;
    }

    .cl-formButtonPrimary {
      background: var(--global-purple-color);
      box-shadow: none;
    }

    .cl-footer {
      background: none;
      flex-direction: column-reverse;

      & > div:last-child {
        border: none;
        padding: 32px 0 32px 0;

        & > span {
          white-space: nowrap;
        }

        p {
          color: #465056;
          text-align: center;
          font-family: var(--font-inter);
          font-size: 12px;
          font-weight: 400;
        }
      }

      .cl-footerAction {
        display: flex;
        flex-direction: column;
        padding: 0;
        align-items: center;
      }

      .cl-footerActionText {
        color: #010B11;
        text-align: center;
        font-family: var(--font-inter);
        font-size: 14px;
        font-weight: 400;
      }

      .cl-footerActionLink {
        color: var(--global-purple-color);
        font-family: var(--font-inter);
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
      }

      .cl-footerActionLink:after {
        content: ' to your Narus workspace';
        color: #465056;
        font-family: var(--font-inter);
        font-size: 14px;
        font-weight: 400;
        display: inline;
      }
    }

    .termsSection {
      margin-top: 60px;

      .MuiTypography-root, .termsLink {
        font-family: var(--font-inter);
        font-size: 12px;
        font-weight: 400;
      }

      .MuiTypography-root {
        color: #465056;
      }

      .termsLink {
        color: var(--global-purple-color);
        text-decoration: none;
      }
    }
  }
}
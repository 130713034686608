.planContentSeatsRow {
  .seatsCount {
    color: var(--Text-Primary, #09151D);
    font-family: "Avenir Next";
    font-size: 14px;
    font-weight: 400;
  }

  .planContentSeatsInput {
    display: flex;
    border-radius: 4px;
    border: 1px solid #CFD9DF;
    width: 301px;
    height: 36px;
    align-items: center;
    justify-content: space-between;

    & > div > div > .MuiInputBase-input {
      padding: 8px 16px 8px 12px;
      color: var(--Text-Primary, #09151D);
      font-family: "Avenir Next";
      font-size: 14px;
      font-weight: 400;
    }

    & > div > div > .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .buttonWrapper {
      display: flex;
      height: 100%;

      .subtractButton {
        border: solid #CFD9DF;
        border-width: 0 1px 0 1px;
        border-radius: 0;
        min-width: 37px;
        width: 37px;
        height: 100%;

        .subtractIcon {
          color: rgba(196, 196, 196, 1);
          width: 16px;
          height: 16px;
        }
      }

      .addButton {
        border: 0;
        min-width: 37px;
        width: 37px;
        height: 100%;

        .addIcon {
          color: rgba(196, 196, 196, 1);
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
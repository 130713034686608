.featureCardContainer {
  height: 100%;
  border-radius: 5px;

  .featureCardContentContainer {

    .featureCardTitleContainer {
      background-color: #F8F7FF;
      padding: 10px 20px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: center;

      & > svg {
        margin-bottom: 5px;
      }

      & > p {
        font-size: 14px;
      }
    }

    .featureCardDescription {
      text-align: center;
      font-size: 14px;
      padding-bottom: 15px;
    }

    .featureCardIcon {
      color: var(--card-icon-color);
    }

    .currentFeature,
    .newFeature {
      text-align: center;
      font-size: 16px;
    }

    .newFeature.limit,
    .currentFeature.limit {
      font-size: 14px;
      font-style: italic;
    }

    .newFeature.downgrade,
    .newFeature.limit.downgrade {
      color: #C21D1D;
    }

    .newFeature.upgrade,
    .newFeature.limit.upgrade {
      color: #1A7164;
    }

  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .featureCardContentContainer.MuiCardContent-root {
    padding: 10px;
  }

  .featureCardLimits {
    & > svg.arrow,
    & > div.MuiBox-root {
      margin-left: 5px;
    }
  }
}
@use 'src/styles/spacing';
@use 'src/styles/font';

.organisationLimit {
  &_Container {
    padding: spacing.$xl-100;
    width: 40vw;
    height: 100%;
  }

  &_TitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: spacing.$xl-200;
  }

  &_Title {
    display: flex;
    align-items: center;

    & .MuiTypography-root {
      @include font.typography-semibold(h6);
      margin: 0 spacing.$s-100;
    }
  }

  &_CloseIcon {
    color: var(--color-icon-subtle)
  }

  &_TitleIcon {
    & > svg {
      color: var(--global-purple-color);
    }
  }
}

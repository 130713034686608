/**
 * Use switch example:
 * 
 *     // someComponent.tsx
 *     const MyComponent = () => {
 *          return (<>
 *              <Switch className='mySwitch'/>
 *          </>)
 *     }
 *     // someOtherFile.scss
 *     @use 'src/styles/switch';
 *     
 *     .mySwitch {
 *         @include switch.default;
 *     }
 */

@mixin default {
    &.MuiSwitch-root {
        & .MuiSwitch-switchBase {
            color: var(--color-icon-disabled);
            & + .MuiSwitch-track {
                background-color: var(--color-icon-disabled);
            }
            &.Mui-checked {
                color: var(--color-icon-brand);
    
                & + .MuiSwitch-track {
                  background-color: var(--color-icon-brand);
                }
            }
        }
    }
}
.messageFilesContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
    flex-wrap: wrap;
    
    .messageFilesItem {
        padding: 7px 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background-color: #80B6BC12;

        .itemIcon.MuiSvgIcon-root {
            width: 32px;
            height: 32px;
            color: #80B6BC;
        }

        .itemDescription {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .itemName.MuiTypography-root {
            color: var(--global-text-primary-color);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.16px;
            white-space: normal;
        }

        .itemDate.MuiTypography-root {
            color: var(--global-text-primary-color);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.16px;
        }
    }
}
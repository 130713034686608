.getStartedContainer {
  .leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    .contentWrapper {
      margin-top: 80px;
      width: 65%;
      display: flex;
      flex-direction: column;
      gap: 80px;

      .detailsContainer {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .title {
          color: var(--Text-Primary, #09151D);
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
        }

        .subtitle {
          color: var(--Text-Primary, #09151D);
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        .field {
          .label-firstname, .label-lastname {
            color: var(--Text-body, #182228);
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
          }

          .label-lastname {
            margin-top: 12px;
          }

          .checkboxWrapper {
            display: flex;
            align-items: flex-start;
            margin-top: 12px;

            .checkbox {
              transform: scale(0.8);
              padding: 0 9px 9px;
            }

            .checkboxLabel {
              color: var(--Text-secondary, #5C666D);
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              padding-top: 3px;
            }
          }
        }
      }

      .buttonsContainer {
        display: flex;
        gap: 16px;
        width: 100%;

        .continueButton {
          background: var(--Primary-600, #825ECA);
          font-size: 14px;
          font-weight: 500;
          padding: 6px 16px;
          width: 45%;

          &:hover {
            background-color: #5A32A3;
          }
        }

        .skipButton {
          color: var(--Primary-600, #825ECA);
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          padding: 6px 8px;
        }
      }
    }

    .helpContainer {
      width: 65%;
      height: 100%;
      display: inline-flex;
      align-items: end;
      margin-bottom: 50px;
      gap: 4px;
      cursor: pointer;

      .helpIcon {
        color: var(--Primary-600, #825ECA);
        width: 24px;
      }

      .helpText {
        color: var(--Product-Primary-Dark30, #5B428D);
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .rightContainer {
    background: radial-gradient(89.52% 134.44% at 22.95% 0%, rgba(161, 120, 250, 0.04) 0%, rgba(163, 117, 252, 0.04) 17.71%, rgba(107, 197, 209, 0.06) 35.86%, rgba(0, 109, 122, 0.04) 57.9%, rgba(163, 117, 252, 0.03) 91.9%);

    .contentWrapper {
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 80px 10px;
      flex-direction: column;
      align-items: center;
      gap: 57px;
      flex-shrink: 0;

      .rightTitle {
        color: var(--Text-Primary, #09151D);
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }

      .image {
        width: 80%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}
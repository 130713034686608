@use 'src/styles/button';

.appDialogContainer {
    .MuiPaper-root {
        box-shadow: none;
        border: solid 1px var(--global-outline-color);
        border-radius: 4px;
        width: 50%;
    }
  
    .dialogTitle.MuiTypography-root {
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px var(--global-outline-color);
        color: var(--global-text-primary-color);
    }
  
    .dialogContent {
        &.MuiDialogContent-root {
            padding: 16px 24px;
        }
        & .MuiTypography-root{
            color: var(--global-text-primary-color);
        }
    }

    .primaryButton, .secondaryButton {
        @include button.default
    }
}
  
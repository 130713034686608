/**
 * Use button example:
 * 
 *     // someComponent.tsx
 *     const MyComponent = () => {
 *          return (<>
 *              <Button className='myButton'>Some text</Button>
 *              <Button className='myButton' variant='outline'>Some text</Button>
 *              <Button className='myButton' variant='contained'>Some text</Button>
 *              <Button className='myButton' size='small'>Some text</Button>
 *              <Button className='myButton' size='large'>Some text</Button>
 *              <Button className='myButton' color='error'>Some text</Button>
 *              // ...and other combinations
 *          </>)
 *     }
 *     // someOtherFile.scss
 *     @use 'src/styles/button';
 *     
 *     .myButton {
 *         @include button.default;
 *     }
 */

@use 'src/styles/font';
@use 'src/styles/spacing';

@mixin _size {
    &.MuiButton-sizeLarge {
        padding: spacing.$s spacing.$l;
        @include font.medium;
        &.MuiButton-text {
            padding: spacing.$s spacing.$s-200;
        }
    }
    &.MuiButton-sizeMedium {
        padding: spacing.$s spacing.$m;
        @include font.medium(small);
        &.MuiButton-text {
            padding: spacing.$s spacing.$s;
        }
    }
    &.MuiButton-sizeSmall {
        padding: spacing.$xs spacing.$s-200;
        @include font.medium(small);
    }
}

@mixin default {
    @include _size;
    &.MuiButton-contained  {
        background-color: var(--color-surface-brand);
        color: var(--color-text-inverse);
        &:hover {
            background-color: var(--color-surface-brand-contrast);
        }
        &.Mui-disabled {
            background-color: var(--color-surface-disabled-light);
            color: var(--color-text-disabled-contrast);
        }
        &Error {
            background-color: var(--color-surface-danger);
            &:hover {
                background-color: var(--color-surface-danger-contrast);
            }
        }
    }
    &.MuiButton-outlined {
        border-color: var(--color-border-brand);
        color: var(--color-text-brand);
        &:hover {
            background-color: var(--color-surface-brand-contrast-light);
            color: var(--color-text-brand);
        }
        &.Mui-disabled {
            background-color: transparent;
            border-color: var(--color-text-disabled-contrast);
            color: var(--color-text-disabled-contrast);
        }
        &Error {
            color: var(--color-text-danger);
            border-color: var(--color-border-danger);
            &:hover {
                color: var(--color-text-danger);
                background-color: var(--color-surface-danger-contrast-light);
            }
        }
    }
    &.MuiButton-text {
        color: var(--color-text-brand);
        &:hover {
            background-color: var(--color-surface-brand-contrast-light);
        }
        &.Mui-disabled {
            background-color: transparent;
            color: var(--color-text-disabled-contrast);
        }
        &Error {
            color: var(--color-text-danger);
            &:hover {
                background-color: var(--color-surface-danger-contrast-light);
            }
        }
    }
}

@mixin icon {
    &.MuiIconButton-root {
        color: var(--color-icon-brand);
        &:hover {
            background-color: var(--color-surface-brand-contrast-light);
        }
        &.Mui-disabled {
            background-color: transparent;
            color: var(--color-icon-disabled);
        }
        &Error {
            color: var(--color-text-danger);
            &:hover {
                background-color: var(--color-surface-danger-contrast-light);
            }
        }
    }
}
.infoScreen {
    &Container {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gradient-info);
    }

    &Header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &Logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6.375px;
        img {
            min-width: 38.25px;
            max-width: 38.25px;
            min-height: 38.25px;
            max-height: 38.25px;
        }
    }
}
@use 'src/styles/dimension';

.AuditLogWarningLevelChip {
    &_High {
      &.MuiChip-root {
        border-radius: dimension.$s;
        background-color: var(--color-surface-danger-contrast-light);
      }
  
      & .MuiChip-label {
        color: var(--color-text-danger-contrast);
      }
    }
  
    &_Medium {
      &.MuiChip-root {
        border-radius: dimension.$s;
        background-color: var(--color-surface-warning-contrast-light);
      }
  
      & .MuiChip-label {
        color: var(--color-text-warning-contrast);
      }
    }
  
    &_Low {
      &.MuiChip-root {
        border-radius: dimension.$s;
        background-color: var(--color-surface-success-contrast-light);
      }
  
      & .MuiChip-label {
        color: var(--color-text-success-contrast);
      }
    }
  }
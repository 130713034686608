.roundIcon {
    &Border {
        grid-area: 1 / 1;
    }
    &Box {
        grid-area: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.AccountCreationCompleted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    gap: 36px;

    .MuiTypography-root {
        font-family: var(--global-font-family);
    }

    &_Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        button {
            text-transform: none;
            &.MuiButton-outlined, &.MuiButton-text {
                color: var(--global-primary-color);
                background-color: #FFFFFF;

                &:hover {
                    text-decoration: none;
                    background-color: rgba(0, 109, 122, 0.04);
                }
            }

            &.MuiButton-contained {
                background-color: var(--global-primary-color);
                color: #FFFFFF;
                &:hover {
                    background-color: var(--global-primary-color);
                }
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 160%;
        }
    }

    &_Title.MuiTypography-root {
        text-align: center;
        color: var(--global-text-primary-color);
        font-size: 35px;
        font-weight: 700;
        line-height: 133.4%;
    }

    &_Subtitle.MuiTypography-root {
        font-size: 20px;
        text-align: center;
        line-height: 26px;
        color: var(--global-text-primary-color);
    }
}

.globalSettings {
  padding: 10px 10px 10px 20px;


  .settingsTabContainer {
	border-bottom: 1px solid rgba(9, 21, 29, 0.12);

	& button {
	  color: rgba(9, 21, 29, 0.6);
	  font-size: 16px;
	  font-weight: 600;

	  &.Mui-selected {
		border-bottom: 2px solid #523B7E;
		transition: all 0.3s ease-out;
		color: #523B7E;
	  }
	}
  }

  & .title.MuiTypography-root {
	color: #172B4D;
	font-size: 26px;
	font-weight: 700;
	line-height: 42px;
  }

  & .sectionTitle.MuiTypography-root {
	margin-top: 10px;
	padding: 16px 2px;
	font-size: 18px;
	border-bottom: 1px solid #E0E0E0;
  }

  & .subtitle.MuiTypography-root {
	font-size: 16px;
  }

  & .subsubtitle.MuiTypography-root {
	font-size: 14px;
  }

  & .body.MuiTypography-root {
	font-size: 16px;
	font-weight: 500;
	margin: 9px 0;
  }
}

.settingContainer {
  padding: 0 15px;

  .sectionContainer {
	width: 100%;
	padding: 10px;
	margin-top: 9px;
  }

  .organizationIconWrapper {
	display: flex;
	align-items: center;

	.organizationIconContainer svg {
	  border-radius: 4px;
	  border: 1px dashed #E5E5EA;
	}
  }

  .organizationNameContainer {
	padding: 15px;
	flex-grow: 1;
  }

  .saveButton, .cancelButton {
	margin-top: 15px;
	float: right;
  }

  .cancelButton {
	margin-right: 10px;
  }

  .domainNameContainer {
	border-radius: 4px;
	border: 1px solid #CCC;
	margin: 15px 0;
	padding: 16px 16px 23px 16px;
  }

  .newDomainTextfield {
	margin: 22px 0 7px 0;
  }

  .accountDomainRow {
	margin: 4px 7px;
	display: flex;
	border-bottom: 1px solid rgba(128, 128, 128, 0.25);

	.accountDomainName {
	  flex-grow: 1;
	  align-content: center;
	  color: #44546F;
	}
  }

  .addDomainButton {
	padding: 8px 22px;
  }

  .domainActionIcon {
	color: var(--global-primary-color);

	&.disabled {
	  color: grey;
	}
  }

  .addUserButton.MuiButton-root {
	border-radius: var(--none, 0px) 4px 4px var(--none, 0px);
	border: 1px solid var(--Dark-Aquamarine, #006D7A);
	background: rgba(242, 253, 255, 0.94);
	min-width: 80px;
	align-self: normal;
  }

  .newUserInput {
	margin: 22px 0 7px 0;

	.MuiInputBase-root {
	  display: flex;
	  padding: 0;
	}
  }

  .domainUsersAccordion {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }
}





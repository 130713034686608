.AlertsChip.MuiChip-root {
  padding: 3px 4px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  background-color: #FF6633;
  height: 24px;

  & > svg {
    width: 16px;
    height: 16px;
    fill: white;
    margin: 0;
  }

  & > span {
    padding: 0 3px;
    color: white;
    font-family: var(--global-font-family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }
}
@use 'src/styles/spacing';
@use 'src/styles/button';

.AccountCreationForm {
    gap: spacing.$xl;

    &_Controls {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 25px;
        padding: 0 75px;
        margin-right: 75px;
        overflow-y: scroll;
    }

    &_Control {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 25px;

        &Header.MuiTypography-root {
            margin: 0;
        }

        &Subheader.MuiTypography-root {
            font-size: 14px;
            font-weight: 700;
        }

        &Item {
            display: grid;
            grid-template-columns: 1fr 56px;

            & > .MuiFormControl-root {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 3;
            }

            & > .MuiSvgIcon-root {
                width: 56px;
                height: 56px;
                padding: 16.5px 14px;
                grid-area: 1 / 2 / 4 / 2;
                z-index: 1;
                color: #6b7280;
                transition: color 100ms ease-out;

                &:hover {
                    cursor: pointer;
                    color: #006D7A;
                }
            }
        }
    }

    &_Actions {
        text-align: center;
    }

    &_Action {
        @include button.default;
    }


    .newUserInput {
        margin: 22px 0 7px 0;

        .MuiInputBase-root {
            display: flex;
            padding: 0;
        }
    }

    .addUserButton.MuiButton-root {
        border-radius: var(--none, 0px) 4px 4px var(--none, 0px);
        border: 1px solid var(--Dark-Aquamarine, #006D7A);
        background: rgba(242, 253, 255, 0.94);
        min-width: 90px;
        align-self: normal;
    }

    .accountDomainRow {
        margin: 4px 7px;
        display: flex;
        border-bottom: 1px solid rgba(128, 128, 128, 0.25);

        .accountDomainName {
            flex-grow: 1;
            align-content: center;
            color: #44546F;
        }
    }
}

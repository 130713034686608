.AccountCreationError {
    height: 100vh;
    padding: 100px;

    &_Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80vh;
        gap: 36px;
    }

    &_Avatar {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &_Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    &_Title.MuiTypography-root {
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        color: var(--global-text-primary-color);
    }

    &_Subtitle.MuiTypography-root {
        font-size: 20px;
        text-align: center;
        line-height: 26px;
        color: var(--global-text-primary-color);
    }
}

.awsSubscriptionDetails{
  .subscriptionDetails {
    margin: 32px 0 16px 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--Text-Primary, #09151D);
  }

  .awsSubscriptionDetailsSubtext {
    font-size: 16px;
    font-weight: 400;
    color: var(--Text-Primary, #09151D);
  }

  .goToAWSButton.MuiButtonBase-root {
    background-color: #825ECA;
    color: white;
    float: right;
    margin-top: 24px;
  }
}

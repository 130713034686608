@use 'src/styles/admin';
@use 'src/styles/button';
@use 'src/styles/switch';

.aiModelsControlContainer {
  @include admin.container;

  .aiModelsControlTitle {
    @include admin.title;
    margin-bottom: 10px;
  }

  .aiModelsControlSectionSubtitle {
    @include admin.subtitle;
    margin-bottom: 25px;
  }

  .aiModelsControlSectionTitle {
    @include admin.sectionTitle;
    margin-bottom: 25px;
  }
}


.aiModelsTable {
  border: 1px solid var(--color-border-default);
  padding-left: 15px;
  padding-bottom: 25px;
  border-radius: 4px;
}

.aiModelRow {
  align-items: center;
  padding: 20px 0 5px 0;
  border-bottom: 1px solid var(--color-border-default);

  & > div {
    display: flex;
    align-items: center;

    & > p {
      margin-left: 15px;
      color: var(--global-text-primary-color);
    }

    &:nth-child(2),
    &:nth-child(3) {
      justify-content: center;
    }

  }

  &.disabled > div > * {
    color: var(--color-text-disabled);
  }

}

.aiModelColumnIcons {
  padding-left: 25px;

  & > svg {
    cursor: pointer;
    color: var(--color-icon-brand);
  }

  &.disabled > * {
    color: var(--color-icon-disabled);
  }
}

.switchModelIcon {
  border-left: 1px solid var(--color-border-default);
  padding-left: 10px;
  .switchItem {
    @include switch.default;
  }
}

.noModelsEnabledWarning {
  margin: 10px 0;
}

.newAiModelContainer {

  & > h5 {
    color: var(--color-text-default);
    font-size: 18px;
    font-weight: 700;
    margin: 30px 0 20px 0;
  }
}

.addAiModelButton.MuiButtonBase-root {
  @include button.default;
}

.maxAiModelsReachedAlert, .addAiModelButton.MuiButtonBase-root {
  margin-top: 15px;
}

.connectAiButtonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.connectAiButton {
  margin-top: 10px;
  @include button.default;
}
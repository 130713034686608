.signInContainer {
  background: var(--gradient-info);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;

  .titleRow {
    display: flex;
    height: fit-content;
    gap: 17px;
    align-items: center;
    position: fixed;
    padding: 38px 51px;

    & .MuiTypography-root {
      color: var(--Dark, #151C33);
      font-size: 25px;
      font-weight: 700;
    }
  }

  .login {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .cl-cardBox {
      width: 507px;
      height: 588px;
      border-radius: 7px;
      border: 1px solid #ECECED;
      box-shadow: 0px 3px 21px 0px rgba(25, 28, 33, 0.20), 1px 1px 0px 0px rgba(0, 0, 0, 0.08);

      .cl-card {
        border-radius: 7px;
        border-bottom: 1px solid #ECECED;
        box-shadow: none;
        align-items: center;
        height: 456px;
        gap: 0;
        padding: 30px 3px 20px 3px;
        justify-content: flex-end;
      }
    }

    .cl-logoBox {
      height: fit-content;

      .cl-logoImage {
        width: 154px;
        height: 41px;
        padding: 5.375px 0 5.375px 0;
      }
    }

    .cl-header {
      gap: 30px;

      & > div:nth-child(2) {
        gap: 13px;
      }

      .cl-headerTitle {
        font-size: 20px;
      }

      .cl-headerSubtitle {
        color: #747686;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .cl-main {
      width: 322px;
      gap: 35px;
    }

    .cl-socialButtons {
      border-radius: 6px;
      border: 1px solid var(--Brand-Main, #A375FC);
      box-shadow: 0px 2px 9px -5px rgba(25, 28, 33, 0.20);
      margin-top: 35px;

      .cl-socialButtonsBlockButton {
        border: none;
        color: var(--global-purple-color);
        font-size: 14px;
        font-weight: 500;

        & > span {
          gap: 5px;
        }
      }
    }

    .cl-socialButtonsBlockButton__google > span > span:nth-child(1) {
      background-color: var(--global-purple-color);
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-image: url('https://img.clerk.com/static/google.svg');
      -webkit-mask-size: cover;

      & > img {
        opacity: 0;
      }
    }

    .cl-dividerText {
      font-family: var(--global-font-family);
      font-size: 14px;
      font-weight: 500;
    }

    .cl-form {
      gap: 28px;

      .cl-formField {
        & > div:first-child {
          gap: 6px;
        }
      }

      .cl-formFieldLabel {
        color: #172B4D;
        font-size: 14px;
        font-weight: 600;
      }

      .cl-formFieldInput {
        margin-bottom: -6px;
      }

      .cl-formFieldInput, .cl-socialButtonsBlockButton {
        height: 32px;
        border-radius: 7px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        box-shadow: 0 2px 9px -5px rgba(25, 28, 33, 0.20);
      }

      .cl-formButtonPrimary {
        text-indent: -9999px;
        border-radius: 6px;
        border: 1px solid var(--global-purple-color);
        background: var(--global-purple-color);
        box-shadow: 0px 2px 9px -5px rgba(25, 28, 33, 0.20);

        .cl-buttonArrowIcon {
          opacity: 100%;
        }
      }

      .cl-formButtonPrimary:before {
        text-indent:0;
        float: left;
        display: inline-flex;
        justify-content: center;
        content: 'Sign in';
        text-transform: none;
      }
    }

    .cl-footer {
      height: 132px;
      margin: 0;
      padding: 0;
      justify-content: space-evenly;

      .cl-footerActionText {
        color: #626F86;
        font-size: 14px;
        font-weight: 500;
      }

      .cl-footerActionLink {
        color: var(--global-purple-color);
        font-size: 14px;
        font-weight: 600;
      }

      & > div:last-child {
        padding-top: 23px;
        border-top: 1px solid #ECECED;

        p {
          color: #626F86;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .termsSection {
      margin-top: 18px;
      text-align: center;

      .MuiTypography-root {
        color: #747686;
        font-size: 16px;
        font-weight: 500;
      }

      .termsLink {
        color: var(--global-purple-color);
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
      }

      .redirectIcon {
        width: 9px;
        vertical-align: sub;
      }
    }
  }
}
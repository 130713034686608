.billingTabsContainer {
  padding-top: 5px;

  &.MuiGrid-item {
    flex-shrink: 1;
  }

  .licenseSubmenu.MuiTypography-root {
    color: rgba(24, 56, 78, 0.8);
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    text-decoration: none;
    cursor: pointer;

    svg {
      margin-right: 6px;
    }
  }

  .active.MuiTypography-root {
    color: var(--Primary-Main, #825ECA);
  }
}
.noBudgetAdminBanner{
  width: 100%;

  & .MuiAlert-message {
	font-size: 16px;
	font-weight: 500;
  }
}

.adminContainer {
  &.MuiGrid-root {
    flex-grow: 1;
    background-color: white;
  }
}

.datePickerContainer.MuiGrid-root {
  padding: 0px 0px 30px 0px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 7.5px;
}
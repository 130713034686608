@use 'src/styles/admin';
@use 'src/styles/font';
@use 'src/styles/button';
@use 'src/styles/spacing';

.budgetControlContainer {
  @include admin.container;

  .title {
	@include admin.title;
	margin-bottom: 10px;
  }

  .subtitle {
	@include admin.subtitle;
	margin-bottom: 15px;
  }

	.tabsContainer {
	  border-bottom: 1px solid var(--color-border-default);

	  & .MuiButtonBase-root {
	    @include font.medium(large);
	    color: var(--color-text-subtle);
	  }

	  & .Mui-selected {
		border-bottom: 2px solid var(--color-border-brand);
		transition: all 0.3s ease-out;
		color: var(--color-text-brand);
	  }
	}

  .buttonsRow {
	& > button {
	  @include button.default;
	  box-shadow: none;
	}
  }

  .organizationBudgetContainer {
	display: flex;
	padding: spacing.$m;
	align-items: flex-end;
	flex: 1 0 0;
	border-radius: 12px;
	border: 1px solid var(--color-border-default);
	margin-top: spacing.$xl;
  	justify-content: space-between;

	& .budgetLimitContainer {
	  display: flex;
	  flex-direction: column;

	  & .title {
		color: var(--global-text-primary-color);
		font-size: 18px;
		font-weight: 600;
	  }

	  & .subtitle {
		color: var(--global-text-primary-color);
		font-size: 30px;
		font-weight: 600;
		margin-bottom: 0;

		& svg {
		  margin-bottom: 7px;
		}
	  }
	}
	.renewedBudget {
	  color: var(--global-text-primary-color);
	  font-size: 14px;
	  font-weight: 400;
	  line-height: 20px;
	}
  }
  .noTeamsButtonContainer > button {
	margin: 0 5px;
	color: var(--color-text-brand);
    border: 1px solid var(--color-border-brand);
  }

  .deleteBudgetContainer {
	align-self: center;
	flex-grow: 1;

	& button {
	  float: right;
	}
  }

  .tabPanelContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
  }

  .budgetControlNoTeams {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
  }
}
.appMenuMainItemContainer {
    &.MuiListItem-root {
        padding: 0;
        position: relative;
    }
    
    .MuiListItemButton-root:hover {
        background-color: #F3EFFA73;
    } 

    &.selected {
        background-color: #F3EFFA73;

        .MuiTypography-root {
            color: var(--Primary-Main, #825ECA);
            font-weight: 600;
        }

        .MuiSvgIcon-fontSizeMedium {
            color: var(--Primary-Main, #825ECA);
        }
    }

}
.userNameContainer {
  margin: 0 8px;
  color: #0D082C;

  & p {
	font-size: 16px;
	font-weight: 600;
  }
}

.botNameContainer {
  margin: 0 8px 15px;
  color: #0D082C;

  & p {
	font-size: 16px;
	font-weight: 600;
  }
}

.promptResultHelperText, .promptResultHelperText.MuiTypography-body1 {
  color: rgba(13, 8, 44, 0.40);
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
}

.advancedSettingsContainer {
  padding: 0 18px;
}

.arrowIconContainer {
  background-color: var(--global-primary-color);
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: flex;

  & > svg {
	color: #FFFFFF;
	font-size: 18px;
  }

  &.disabled {
    cursor: auto;
    background-color: rgba(204, 204, 204, 1);
  }
}

.helpIcon {
  margin-left: 5px;
  margin-right: 7px;
}

.optimizedIcon {
  width: 12px !important;
  height: 12px !important;
}

.optimizedContainer {
  display: flex;
  align-items: center;
  padding: 4px 6px 4px 6px;
  gap: 5px;
  border-radius: 100px;
  background: linear-gradient(180deg, rgba(128, 182, 188, 0.4) -2.56%, rgba(107, 197, 209, 0.4) 38.71%, rgba(173, 151, 248, 0.4) 77.74%, rgba(181, 145, 253, 0.4) 108.97%);
  color: #523B7E;

  & .optimizedText.MuiTypography-root {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
  }
}

.chatContainer {
  height: 82vh;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;

  & .chatMessageContainer {
	flex-grow: 1;
	overflow: auto;
  }

}

.chatMessageContainer {
  padding: 15px 13px;
  max-width: 85%;
  margin: 0 10%;
}

.chatInputContainer {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .chatInputAdornment {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.chatInput.MuiPaper-root {
  display: flex;
  align-items: center;
  box-shadow: none;

  & .chatInputDivider {
	height: 30px;
  }
}

.messageWrapper {
  display: flex;
  margin: 17px 0;

  &.botMessage, &.userMessage {
	justify-content: flex-start;
  }
}

.messageContainer {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  max-width: 100%;

  &.botMessage, &.userMessage {
	gap: 30px;
	flex-direction: row;
  }

  & .loadingText {
	margin: 0 8px;
  }

  & .modelIcon {
	width: 40px;
	min-width: 40px;
	height: 40px;
	color: var(--global-primary-color);
  }

  & .avatar {
	border-radius: 30px;

	&.--optimized {
	  background: transparent;
	  background-image: linear-gradient(90deg, white, white), linear-gradient(149.42deg, #80B6BC -35.18%, #6BC5D1 7.97%, #AD97F8 48.78%, #B591FD 81.43%, #B591FD 81.43%);
	  background-clip: padding-box, border-box;
	  background-origin: border-box;
	  border: 2px solid transparent;
	  color: var(--global-text-primary-color);
	}
  }

  & .messageContent {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	white-space: nowrap;
	overflow: auto;

	& .messageHeader {
	  display: flex;
	  align-items: center;
	}
  }
}

.optimizedTooltipHeader.MuiTypography-root {
  font-weight: 600;
}

.loadingText.MuiTypography-root {
  color: var(--global-primary-color);
  margin-left: 8px;
}

.message {
  position: relative;
  border: none;
  flex-grow: 1;
  max-width: 100%;

  &.MuiPaper-root {
	border: none;
  }

  & table {
	margin: 10px 0;

	& tr {
	  border-bottom: 1px solid lightgray;

	  &:first-child {
		font-weight: bold;
	  }

	  & td {
		padding: 10px;
	  }
	}
  }

  &.errorMessageContainer {
	padding: 10px 15px;
	color: #4C0A0A;
	background-color: #f8e8e8;
	display: flex;

	& .errorMessageWrapper {
	  display: flex;
	}

	& svg {
	  color: #4C0A0A;
	  margin-right: 5px;
	}
  }
}

.tryAgainButtonContainer {
  display: flex;
  justify-content: flex-end;

  & .tryAgainButton.MuiButtonBase-root {
	background-color: white;
	font-size: 14px;
  }
}

.refinePromptButton.MuiButtonBase-root {
  margin-top: 17px
}

.promptInput.MuiTextField-root {
  position: relative;
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.19);
  border-radius: 40px;

  &::before {
	content: "";
	position: absolute;
	inset: 0;
	border: 2px solid transparent;
	background: conic-gradient(from 180deg at 50% 50%, #80B6BC 0deg, #6BC5D1 133.2deg, #8AAFE4 250.2deg, #B591FD 351deg, #80B6BC 360deg) border-box;
	-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	border-radius: 40px;
  }

  &.disabled::before {
    border: 2px solid #CCC;
    background: #FFF;
  }

  & .MuiOutlinedInput-input {
	box-shadow: none;
  }

  & .MuiOutlinedInput-notchedOutline {
	border: none;
  }
}

.loadingText.MuiTypography-body1 {
  margin-left: 10px;
  color: var(--global-primary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.promptOptimizationButton.promptOptimizationButtonChat {
  & .MuiButton-icon {
	margin: 0;
  }
}

.magicWandIcon.disabled {
  color: #CCCCCC
}

.optimizationBadge .MuiBadge-badge {
  background-color: #523B7E;
  width: 15px;
  height: 15px;
}

.modelDisabledAlert {
  width: fit-content;
}

@media (min-width: 768px) and (max-width: 2559px) {

  .chatContainer {
	&.chatContainerTrial {
	  height: 73vh;
	}
  }

}

@media (min-width: 2560px) {

  .chatContainer {
	&.chatContainerTrial {
	  height: 83vh;
	}
  }

}
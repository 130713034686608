.submenuContent {
  padding: 0 84px 0 64px;

  & .MuiTypography-root {
    color: var(--Text-Primary, #09151D);
  }

  .licenseTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .licenseTitleWrapper {
      display: flex;
      align-items: center;
    }

    .changePlanButton.MuiButtonBase-root {
      color: #825ECA;
      border: 1px solid rgba(130, 94, 202, 0.50);
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      height: 36px;
      padding: 6px 16px;
      margin-left: 8px;
    }

    .licenseName {
      &::first-letter {
        text-transform: capitalize;
      }

      color: var(--Primary-Dark, #7A56C5);
      font-size: 24px;
      font-weight: 600;
      margin-right: 12px;
    }

    .periodChip {
      border: none;
      border-radius: 100px;
      background: #D4EBEE;
      max-height: 24px;
      padding: 3px 4px;

      & ::first-letter {
        text-transform: capitalize;
      }

      .MuiChip-label {
        color: #0C2F32;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.16px;
        padding: 0 6px;
      }
    }
  }

  .benefitsAccordion {
    margin-top: 24px;
    border-radius: 4px;
    border: 1px solid var(--Grey-300, #E0E0E0);
    box-shadow: none;

    &:before {
      height: 0;
    }

    .benefitsTitle {
      font-size: 16px;
      font-weight: 500;
      color: var(--Text-Primary, #09151D);
    }

    .benefitContainer {
      justify-content: space-between;
      gap: 0 10px;
    }

    .benefitContainer, .benefitItem {
      display: flex;
      flex-wrap: wrap;

      .benefitItem .MuiTypography-root {
        color: #193B52;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .activeSeatsProgressBarText.MuiTypography-root {
    margin: 16px 0 5px 0;
    color: var(--Text-Primary, #09151D);
    font-family: var(--global-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .activeSeatsProgressBar {
    .bg-tremor-brand {
      background-color: var(--Primary-Main, #825ECA);
    }

    .bg-tremor-brand-muted\/50 {
      background: var(--Product-Primary-Light80, #E6DFF4);
    }
  }

  .expandMoreIcon {
    width: 18px;
    height: 18px;
  }

  .infoIcon {
    margin-right: 8px;
  }
  
  .checkIcon {
    margin-right: 4px;
    width: 16px;
    color: green;
  }
}

.paymentContainer {
  color: #44546F;


  .summaryContainer, .paymentContainer, .cardContainer, .purchaseDetailWrapper {
	padding: 30px;
  }

  .summaryContainer {
	background-color: rgba(0, 109, 122, 0.09);

	.titleContainer {
	  margin-top: 45px;
	  display: flex;

	  & .MuiTypography-root {
		color: #44546F;
		font-size: 30px;
		font-weight: 700;
		line-height: 133.4%;

		&.MuiTypography-h3 {
		  margin-left: 11px;
		}
	  }

	  .goBackIconLink {
	  	color: rgba(68, 84, 111, 1);
	  }

	  & svg {
		margin-bottom: 5px;
		cursor: pointer;
	  }
	}

	.subtitle {
	  margin-top: 46px;
	  font-size: 19px;
	  line-height: 133.4%;
	}

	.totalPriceContainer {
	  margin-top: 15px;
	  display: flex;
	  align-items: center;

	  .totalPrice {
		font-size: 50px;
		font-weight: 600;
		line-height: 133.4%;
	  }

	  .period {
		font-size: 17px;
		line-height: 133.4%;
		margin-left: 16px;
		width: 20px;
	  }
	}
	.purchaseDetailsContainer {
	  margin: 30px 40px;

	  .purchaseRow {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
	  }

	  .purchaseDivider {
		background-color: #44546F;
		margin: 25px 0;
	  }

	  .seatsButton {
		border-radius: 3px;
		background-color: rgba(0, 109, 122, 0.09);
		color: rgba(68, 84, 111, 1);
		padding: 4px 6px;
		width: fit-content;
	  }
	}

  }

  .cardContainer {
	.userButtonContainer {
	  display: flex;
	  justify-content: flex-end;
	  height: 60px;
	}

	.cardDetailsWrapper {
	  margin: 100px;
	}

	.purchaseButton {
	  margin: 44px 0;
	  background: var(--Primary-Main, #825ECA);
	}

	.paymentConditions.MuiTypography-root {
	  text-align: center;
	  font-size: 19px;
	  font-weight: 500;
	  line-height: 133.4%; /* 25.346px */
	}
  }

  .spinnerContainer {
	display: flex;
	justify-content: center;
  }
}
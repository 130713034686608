.paymentInfoContainer {
  padding: 0 84px 0 64px;

  .paymentInfoTitle {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    color: var(--Text-Primary, #09151D);
  }

  .noBillingInfo {
    margin-top: 16px;

    .noBillingInfoText {
      font-size: 16px;
      font-weight: 500;
      color: var(--Text-Primary, #09151D);
    }
  }

  .paymentCardExpirationInfo {
    font-size: 16px;
    font-weight: 500;
    color: var(--Text-Primary, #09151D);
    margin-bottom: 16px;
  }

  .paymentCardInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 16px 0 32px 0;

    & > .paymentCardInfoLogo {
      width: 40px;
      height: 25px;
    }

    & > div > .paymentCardExpiration,
    & > div > .paymentCardDigits {
      font-size: 16px;
      font-weight: 500;
      color: var(--Text-Primary, #09151D);
      margin-left: 20px;
    }

    & > div > .paymentCardExpiration.noCard,
    & > div > .paymentCardDigits.noCard {
      margin: 0;
    }

    & > .MuiButtonBase-root {
      border-radius: 4px;
      border: 1px solid var(--global-purple-color);
      color: var(--global-purple-color);
      font-size: 13px;
    }
  }

  .paymentInfoFormContainer {
    margin-top: 30px;

    .paymentInfoForm {
      width: 100%;
    }

    .savePaymentInfoButtonContainer {
      display: flex;
      width: 100%;
      justify-content: end;
      margin-top: 16px;

      & > .MuiButtonBase-root {
        border-radius: 4px;
        font-size: 13px;
        padding: 8px 24px;
      }
    }
  }
}
.editSeatsModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;

  .modalHeader {
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.closeIcon {
	  color: #898686;
	  cursor: pointer;
	}

	.modelHeaderWrapper {
	  display: flex;

	  .modalHeaderTitle {
		margin-left: 14px;
		display: flex;
		flex-direction: column;

		.title.MuiTypography-root {
		  font-size: 17px;
		  font-weight: 500;
		  line-height: 133.4%;
		}

		.subtitle.MuiTypography-root {
		  color: rgba(68, 84, 111, 0.60);
		  font-size: 16px;
		  font-weight: 500;
		  line-height: 133.4%;
		}
	  }
	}
  }

  .modalBody {
	padding: 20px;

	.modalBodyWrapper {
	  padding: 10px 0 30px;
	  display: flex;
	  align-items: center;
	  justify-content: center;

	  .seatsField {
		width: 100px;

		.MuiInputBase-root {
		  border-radius: 8px;

		  .MuiInputBase-input {
			text-align: center;
		  }
		}
	  }

	  .changeSeatsButton {
		margin: 0 15px;
		background-color: #efefef;

		.subtractIcon {
		  color: #b2b2b4;
		}

		.addIcon {
		  color: #858585;
		}
	  }
	}
  }

  .updateButton {
	background-color: var(--Primary-Main, #825ECA);

	&:hover {
	  background-color: #754bc7;
	}
  }
}
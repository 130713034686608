.adminErrorScreenWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  flex-grow: 1;
}

.adminErrorScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 50%;

  & > h3 {
    color: var(--global-text-primary-color);
    font-size: 35px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }

  & > h5 {
    color: var(--global-text-primary-color);
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
  }

  & > button {
    border-radius: 4px;
    background: var(--Dark-Aquamarine, #006D7A);
    color: #ffffff;
  }

  & > button:hover {
    border: 1px solid var(--Dark-Aquamarine, #006D7A);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), #006D7A;
  }

}
@use 'src/styles/font';

.AccountCreation {
    &_SideStepper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--color-text-brand);

        & .MuiStepLabel-iconContainer {
            & svg {
                width: 42px; 
                height: 42px;
                color: transparent;
                border: 2px solid var(--color-border-brand);
                border-radius: 21px;

                & text {
                    font-weight: 400;
                    fill: var(--color-text-brand);
                }

            }

            &.Mui-active {
                & svg {
                    background-color: var(--color-surface-brand);
                    & text {
                        font-weight: 400;
                        fill: var(--color-text-inverse);
                    }
                }
            }

            &.Mui-completed {
                width: 42px;
                height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid var(--color-border-brand);
                border-radius: 21px;
                padding: 0;

                & svg {
                    padding: 4px;
                    width: 30px;
                    height: 30px;
                    border: none;
                    background-color: var(--color-surface-brand-contrast-light);
                    & path {
                        fill: var(--color-surface-brand-contrast-light);
                        fill: var(--color-icon-brand);
                    }
                }
            }
        }

        & .MuiStepLabel-label {
            @include font.regular(large);
            color: var(--color-text-subtle);

            &.Mui-active {
                @include font.medium(large);
                color: var(--color-text-brand);
            }
            &.Mui-completed {
                padding-left: 8px;
            }
        }

        & .MuiStepLabel-root {
            padding: 0;
        }

        & .MuiStepConnector-root {
            margin-left: 20px;
            & > span {
                border-left-width: 2px;
                border-color: var(--color-border-brand);
                min-height: 53px;
            }
        }
    }
}
.EmptySection {
    &_Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        gap: 36px;
    }

    &_Text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    &_Title {
        &.MuiTypography-root {
            color: var(--global-text-primary-color);
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: 133.4%;
            text-align: center;
        }
    }

    &_Description {
        &.MuiTypography-root {
            color: var(--global-text-primary-color);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 133.4%;
            text-align: center;
        }
    }
}
.appMenuProfileButtonContainer {
    &.MuiListItem-root {
        padding: 0;
    }

    .buttonContainer {
        gap: 6px;
        transition: padding 200ms ease-in;

        &.open {
            padding: 11px 24px 16px 24px;
        }
        &.closed {
            padding: 11px 12px 16px 12px;
        }
    }
    .buttonText.MuiTypography-root  {
        flex-grow: 1;
        color: #18384EE5;
        font-family: var(--global-font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.15px;
        transition: opacity 200ms ease-out;

        &.open {
            opacity: 1;
        }
        &.closed {
            opacity: 0;
        }
    }
    .buttonIcon.MuiSvgIcon-root {
        color: var(--Primary-Main, #825ECA);
        transition: transform 250ms ease-out;

        &.open {
            transform: rotate(180deg);
        }
        &.closed {
            transform: rotate(0deg);
        }
    }
}
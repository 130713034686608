@mixin title {
    color: var(--global-text-primary-color);
    font-family: var(--global-font-family);
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.4%;
}

@mixin subtitle {
    color: var(--global-text-primary-color);
    font-family: var(--global-font-family);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%;
}
@use 'src/styles/admin';
@use 'src/styles/spacing';

.auditingLogs {
  &Container {
    @include admin.container;
    background-color: white;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &Title {
    @include admin.title;
  }

  &Table {
    display: flex;
  }
}

.checkIcon {
  width: spacing.$m-200;
  color: var(--color-icon-success);
}

.cancelIcon {
  width: spacing.$m-200;
  color: var(--color-icon-danger);
}

.AuditingLogsAction_Pending {
  & > svg {
    color: #FF6633;

  }
}

/**
 * Use font example:
 * 
 *     // someComponent.tsx
 *     const MyComponent = () => {
 *          return (<>
 *              <Typography className='myTypographyTitleText'>Some title</Typography>
 *              <Typography className='myTypographyNormalText'>Some text</Typography>
 *              <Typography className='myTypographyNormalTextColor'>Some text with other color</Typography>
 *              <span className='myNormalText'>Some text</span>
 *          </>)
 *     }
 *     // someOtherFile.scss
 *     @use 'src/styles/font';
 *     
 *     .myTypographyTitleText {
 *         @include font.typography-regular(h1);
 *     }
 *     .myTypographyNormalText {
 *         @include font.typography-regular;
 *      }
 *      // When introducing custom color to text
 *      .myTypographyNormalTextColor.MuiTypography-root {
 *          @include font.regular;
 *          color: #FABADA
 *      }
 *     .myNormalText {
 *         @include font.regular;
 *      }
 */
 
/* Private mixins */

@mixin _color {
    color: var(--color-text-default);
}

@mixin _family {
    font-family: var(--font-brand);
}

@mixin _variant ($variant) {
    @if $variant == display {
        font-size: 64px;
        line-height: 72px;
    }
    @if $variant == h1 {
        font-size: 56px;
        line-height: 64px;
    }
    @if $variant == h2 {
        font-size: 48px;
        line-height: 56px;
    }
    @if $variant == h3 {
        font-size: 40px;
        line-height: 48px;
    }
    @if $variant == h4 {
        font-size: 32px;
        line-height: 40px;
    }
    @if $variant == h5 {
        font-size: 24px;
        line-height: 32px;
    }
    @if $variant == h6 {
        font-size: 20px;
        line-height: 28px;
    }
    @if $variant == large {
        font-size: 18px;
        line-height: 26px;
    }
    @if $variant == base {
        font-size: 16px;
        line-height: 24px;
    }
    @if $variant == small {
        font-size: 14px;
        line-height: 22px;
    }
    @if $variant == xsmall {
        font-size: 12px;
        line-height: 20px;
    }
}

/* Public mixins */ 

@mixin regular ($variant: base) {
    font-weight: 400;
    @include _color;
    @include _family;
    @include _variant($variant)
}

@mixin medium ($variant: base) {
    font-weight: 500;
    @include _color;
    @include _family;
    @include _variant($variant)
}

@mixin semibold ($variant: base) {
    font-weight: 600;
    @include _color;
    @include _family;
    @include _variant($variant)
}

@mixin bold ($variant: base) {
    font-weight: 700;
    @include _color;
    @include _family;
    @include _variant($variant)
}

@mixin typography-regular ($variant: base) {
    &.MuiTypography-root {
        @include regular($variant);
    }
}
@mixin typography-medium ($variant: base) {
    &.MuiTypography-root {
        @include medium($variant);
    }
}
@mixin typography-semibold ($variant: base) {
    &.MuiTypography-root {
        @include semibold($variant);
    }
}
@mixin typography-bold ($variant: base) {
    &.MuiTypography-root {
        @include bold($variant);
    }
}